<template>
  <div
    class="fixed inset-0 z-10 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white border-2 rounded-lg shadow-xl border-evcoin-gold sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <div class="flex justify-end item-center">
            <button @click="$emit('close-modal')" type="button" class="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: outline/x -->
                <svg class="w-6 h-6 text-evcoin-gold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
        <div>
          <div
            class="flex items-center justify-center w-24 h-24 mx-auto bg-transparent rounded-full"
          >
            <svg
              class="w-24 h-24 text-evcoin-gold"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg font-medium leading-6 text-evcoin-gold"
              id="modal-title"
            >
              Refund Tokens
            </h3>
            <div class="mt-2">
              <p class="text-evcoin-main text-md">
                Are you sure you want to refund your tokens to this network? <br>
                <span class="font-semibold text-red-700">You cannot claim them anymore on the target network.</span>
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between h-40 px-10 mt-5 -m-6 bg-no-repeat bg-cover bg-evcoin-footer">
          <button
            @click="$emit('refund', false)"
            type="button"
            class="inline-flex items-center justify-center px-8 py-2 mt-10 mb-6 mr-10 text-base font-bold text-black bg-white rounded shadow-sm w-96 hover:bg-opacity-75"
          >
            Cancel
          </button>
          <button
            @click="$emit('refund', true)"
            type="button"
            class="inline-flex items-center justify-center px-8 py-2 mt-10 mb-6 text-base font-bold text-white rounded shadow-sm w-96 bg-evcoin-gold hover:bg-opacity-75"
          >
            Refund
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
