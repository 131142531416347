<template>
    <div class="fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white border-2 border-solid rounded-lg shadow-xl border-evcoin-gold sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                <div class="flex justify-end item-center">
                    <button @click="$emit('close-modal')" type="button" class="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white">
                        <span class="sr-only">Close</span>
                        <!-- Heroicon name: outline/x -->
                        <svg class="w-6 h-6 text-evcoin-gold" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div>
                    <div class="flex items-center justify-center w-24 h-24 mx-auto">
                        <svg class="w-24 h-24 text-evcoin-gold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="flex flex-col items-center justify-center mt-3 sm:mt-5">
                        <h3 class="text-2xl font-bold leading-6 text-center text-evcoin-gold" id="modal-title">
                            Successfully sent your tokens to the Bridge!
                        </h3>
                        <div class="flex items-center justify-center mt-5">
                            <p class="max-w-sm text-sm text-center text-evcoin-main">
                            Please write down the following information. You will need this information to claim your tokens on the target network. After you write down this information, switch to the target network on your wallet to claim your tokens.
                            </p>
                        </div>
                        <div class="flex flex-col justify-center mt-8 text-center">
                            <p class="flex flex-col font-semibold text-evcoin-gold">
                                Swap ID<span class="text-sm font-semibold text-evcoin-main">{{ swap_id }}</span>
                            </p>
                            <p class="flex flex-col my-10 font-semibold text-evcoin-gold">
                                Unique Identifier<span class="text-sm font-semibold text-evcoin-main">{{ unique_identifier }}</span>
                            </p>
                            <p class="flex flex-col font-semibold text-evcoin-gold">
                                Amount<span class="text-sm font-semibold text-evcoin-main">{{ swap_amount }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center h-40 mt-5 -m-6 bg-no-repeat bg-cover bg-evcoin-footer">
                    <button
                        @click="$emit('close-mdal')"
                        type="button"
                        class="inline-flex items-center justify-center px-8 py-2 mt-10 mb-6 text-base font-bold text-white rounded shadow-sm w-96 bg-evcoin-gold hover:bg-opacity-50"
                    >
                        Done
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['isConnected'])
    },
    data() {
        return {
            swap_id: "",
            unique_identifier: "",
            swap_amount: ""
        }
    },
    mounted() {
        if (localStorage.evcoinLatestSwapId) {
            this.swap_id = localStorage.evcoinLatestSwapId;
            this.unique_identifier = localStorage.evcoinLatestSwapTimestamp;
            this.swap_amount = localStorage.evcoinLatestSwapNumTokens;
        }
    },
}
</script>