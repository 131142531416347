<template>
  <div class="w-screen h-screen overflow-hidden bg-no-repeat bg-cover bg-evcoin-bg">
    <app-header />
    <div class="py-10 mt-20">
      <main class="max-w-3xl mx-auto sm:px-6 lg:px-8">
        <div class="flex flex-col px-10">
          <Network :network="smartContractType" />
          <div class="relative mt-10">
            <div class="absolute inset-y-0 left-0 flex items-center pl-10 pointer-events-none">
              <span class="text-white sm:text-sm">
                $EVCoin
              </span>
            </div>
            <input v-model="swapAmount" type="text" name="price" id="price" class="block w-full text-xl text-center text-white placeholder-white bg-white bg-opacity-25 rounded-full outline-none px-28 pt-7 pb-7" placeholder="Enter the amount you want to swap">
            <button
              v-if="isConnected && swap"
              :disabled="!isConnected && initLoading"
              @click="swapAmount = userBalanceFormatted(swap.token).replace(/,/g,'')"
              class="absolute inset-y-0 right-0 flex items-center pr-10 text-white hover:text-yellow-300"
            >
              Max
            </button>
          </div>
          <div class="flex flex-col items-center justify-between my-8 sm:flex-row">
            <p class="text-sm text-white">You are about to send: {{ swapAmountFormatted }} $EVCoin</p>
            <p class="text-sm text-white">$EVCoin Balance: <span v-if="swap">{{ userBalanceFormatted(swap.token) }}</span><span v-else>0.00</span></p>
          </div>
          <button v-if="!isConnected" @click="init" class="flex items-center justify-center py-5 text-lg font-bold bg-white rounded-full text-evcoin-main hover:bg-opacity-50">
            <span>Connect Wallet</span>
          </button>
          <button v-if="isConnected && initLoading" class="flex items-center justify-center py-5 text-lg font-bold bg-white rounded-full text-evcoin-main hover:bg-opacity-50">
            <span>Connecting Wallet...</span>
          </button>
          <button 
            v-if="isConnected && swap" 
            @click="sendSwap"
            :disabled="isLoading"
            class="flex items-center justify-center py-5 text-lg font-bold bg-white rounded-full text-evcoin-main hover:bg-opacity-50"
          >
            <svg class="mr-3" width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.113 12.0714H0V14.6429H9.113V18.5L14.3 13.3571L9.113 8.21429V12.0714ZM16.887 10.7857V6.92857H26V4.35714H16.887V0.5L11.7 5.64286L16.887 10.7857Z" fill="currentColor"/>
            </svg>
            <span>Initiate Swap</span>
          </button>
          <mtgy class="my-10" />
        </div>
      </main>
    </div>
    <div class="absolute bottom-0 flex items-center justify-center w-full px-6 bg-evcoin-main h-28 sm:h-20">
      <div class="flex flex-col justify-between w-full max-w-3xl mx-auto text-center px-11 sm:flex-row sm:text-left">
        <p class="pt-1 pb-3 text-gray-500 sm:pb-0">$EVCoin <span class="font-semibold text-white" v-if="swap && hasUnclaimedSentFromTarget">{{ swap.unclaimedSentFromTarget.amount }} Available</span><span class="font-semibold text-white" v-else>0.00 Available</span></p>
        <div class="flex items-center justify-center pb-3 sm:pb-2" v-if="swap">
          <button @click="manualClaimModal = true" :disabled="isLoading" class="flex items-center justify-center px-4 py-2 text-sm font-bold bg-white rounded-full text-evcoin-main hover:bg-opacity-50">
            <span v-if="
                    hasUnclaimedSentFromTarget &&
                      swap.unclaimedSentFromTarget.amount != 0.0
                  ">Claim Tokens</span>
            <span v-else>Manual Claim</span>
          </button>
          <button
            v-if="hasUnclaimedInSourceAndNotInitiatedClaiming"
            @click="onRefundModal = true"
            :disabled="isLoading"
            class="flex items-center justify-center px-4 py-2 ml-5 text-sm font-bold bg-white rounded-full text-evcoin-main hover:bg-opacity-50"
          >
            <span>Refund Tokens</span>
          </button>
        </div>
      </div>
    </div>
    <claim-token-modal
      v-if="manualClaimModal"
      @close-modal="manualClaimModal = false"
    />
    <sent-token-modal
      v-if="successfullySentModal"
      @close-modal="successfullySentModal"
    />
    <refund-modal
      v-if="onRefundModal"
      @close-modal="onRefundModal = false"
      @refund="onRefundTokens"
    />
    <notifications position="top right" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BigNumber from "bignumber.js";
import AppHeader from "./components/AppHeader.vue"
import Network from "./components/Network.vue"
import ClaimTokenModal from "./components/ClaimTokenModal.vue";
import SentTokenModal from "./components/SentTokenModal.vue";
import RefundModal from "./components/RefundModal.vue";
import Mtgy from "./components/Mtgy.vue"

export default {
  components: {
    AppHeader,
    Network,
    ClaimTokenModal,
    SentTokenModal,
    RefundModal,
    Mtgy,
  },
  data() {
    return {
      isLoading: false,
      swapAmount: null,
      manualClaimModal: false,
      successfullySentModal: false,
      onRefundModal: false,
      swap_id: '',
      unique_identifier: '',
      swap_amount: 0
    }
  },
  computed: {
    ...mapGetters([
      'isConnected',
      'initLoading',
      'activeNetwork',
      'web3',
      'swap'
    ]),
    smartContractType() {
      if (this.isConnected && !this.initLoading) {
        if (this.activeNetwork.network_id == 1) {
          return 'ERC20'
        } else if (this.activeNetwork.network_id == 56) {
          return 'BEP20'
        }
      } else {
        return 'ERC20'
      }
    },
    swapAmountFormatted() {
      if (!this.swapAmount) {
        return 0
      } else {
        return new BigNumber(parseInt(this.swapAmount)).toFormat();
      }
    },
    hasUnclaimedSentFromSource() {
      if (this.swap) {
        const targetSwap = this.swap.unclaimedSentFromSource;
        return (
          targetSwap &&
          new BigNumber(targetSwap.amount).gt(0) &&
          !targetSwap.isComplete &&
          !targetSwap.isRefunded
        );
      } else {
        return false
      }
    },
    hasUnclaimedSentFromTarget() {
      if (this.swap) {
        const targetSwap = this.swap.unclaimedSentFromTarget;
        return (
          targetSwap &&
          new BigNumber(targetSwap.amount).gt(0) &&
          !targetSwap.isComplete &&
          !targetSwap.isRefunded
        );
      } else {
        return false
      }
    },
    hasUnclaimedInSourceAndNotInitiatedClaiming() {
      if (this.swap) {
        const sourceSwap = this.swap.unclaimedSentFromSource;
        const targetSwap = this.swap.unclaimedSentFromTarget;
        return (
          sourceSwap &&
          new BigNumber(sourceSwap.amount).gt(0) &&
          !sourceSwap.isComplete &&
          !sourceSwap.isRefunded &&
          (!targetSwap || sourceSwap.id !== (targetSwap || {}).id)
        );
      } else {
        return false
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof localStorage.getItem("evcoinLoggedIn") !== 'object' && localStorage.getItem("evcoinLoggedIn") == 'true') {
        this.init(true)
      }
    })
  },
  methods: {
    ...mapActions([
      "init",
      "sendTokensToSwap",
      "asaasInstanceGasCost",
      "asaasGetLatestUserSwap",
      "refundTokens", 
      "getAllSwapContracts"
    ]),
    userBalanceFormatted(tokenInfo) {
      return new BigNumber(tokenInfo.userBalance)
        .div(new BigNumber(10).pow(tokenInfo.decimals))
        .toFormat(2);
    },
    async sendSwap() {
      try {
        this.isLoading = true;
        if (!this.swapAmount || this.swapAmount == "0.00") return;
        const amountToFormat = new BigNumber(this.swapAmount.replace(/,/g,''))
        const correctSendTokenAmount = new BigNumber(amountToFormat)
          .times(new BigNumber(10).pow(this.swap.token.decimals))
          .toFixed();
        await this.asaasInstanceGasCost(this.swap.sourceContract)
        await this.sendTokensToSwap({
          amount: correctSendTokenAmount,
          sourceContract: this.swap.sourceContract,
          tokenContract: this.swap.token.address,
        })
        this.latestSwap = await this.asaasGetLatestUserSwap(this.swap.sourceContract)
        localStorage.evcoinLatestSwapId = this.latestSwap.id;
        localStorage.evcoinLatestSwapTimestamp = this.latestSwap.origTimestamp;
        localStorage.evcoinLatestSwapNumTokens = new BigNumber(
          correctSendTokenAmount
        ).div(new BigNumber(10).pow(this.swap.token.decimals)).toFormat();
        this.successfullySentModal = true
        this.$notify({ type: "error", text: "Successfully initiated your swap!" });
      } catch(err) {
        this.$notify({ type: "error", text: err.message });
      } finally {
        this.isLoading = false
      }
    },
    async onRefundTokens(refund) {
       try {
          if (refund) {
            this.isLoading = true;
            await this.refundTokens({
              instContract: this.swap.sourceContract,
              id: this.swap.unclaimedSentFromSource.id,
              timestamp: this.swap.unclaimedSentFromSource.origTimestamp,
              amount: this.swap.unclaimedSentFromSource.amount,
            })
            this.$notify({ type: "error", text: "Your tokens were successfully refunded!" });
            await this.getAllSwapContracts();
          } else {
            this.onRefundModal = false
          }
        } catch(err) {
          this.$notify({ type: "error", text: err.message });
        } finally {
          this.isLoading = false;
        }
    },
    contractBalance(tokenInfo) {
      return new BigNumber(tokenInfo.contractBalance)
        .div(new BigNumber(10).pow(tokenInfo.decimals))
        .toFormat(2);
    },
  }
};
</script>

<style>
</style>

