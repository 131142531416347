import { createStore } from 'vuex'

import evcoin from '../store/modules/evcoin'

const store = createStore({
    modules: {
        evcoin
    }
})

export default store