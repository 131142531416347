<template>
    <nav class="flex flex-col items-center w-full h-auto px-4 pt-5 pb-10 bg-evcoin-main sm:flex-row sm:justify-between sm:h-20 sm:px-6 lg:px-24 sm:pb-0 sm:pt-0">
      <div class="flex items-center flex-shrink-0 mt-2 mb-5 sm:mt-0 sm:mb-0">
        <img  
          class="block w-auto h-12"
          src="../assets/logo.png"
        />
      </div>
      <div class="flex flex-col items-center justify-between sm:flex-row mt-5 sm:mt-0">
        <div v-if="isConnected && !initLoading && swap" class="flex mx-0 mb-5 text-xs text-white sm:mx-5 sm:mb-0">
          <div class="p-2.5 px-6 rounded-md text-sm bg-evcoin-gold bg-opacity-50">
            {{ start_and_end(web3.address) }}
          </div>
        </div>
        <button v-if="!isConnected" type="button" @click="init" class="inline-flex items-center justify-center w-full px-4 py-2 text-xs font-bold text-center text-black uppercase border-2 border-transparent rounded-md shadow-sm bg-evcoin-gold hover:bg-evcoin-gold hover:bg-opacity-50 sm:text-base sm:w-auto">
          Connect Wallet
          <Spinner v-if="initLoading" />
        </button>
        <button v-if="isConnected && initLoading" type="button" class="inline-flex items-center justify-center w-full px-4 py-2 text-xs font-bold text-center text-black uppercase border-2 border-transparent rounded-md shadow-sm bg-evcoin-gold sm:text-base sm:w-auto">
          Connecting Wallet
          <Spinner />
        </button>
        <button v-else-if="isConnected" type="button" @click="disconnect" class="inline-flex items-center justify-center w-full px-4 py-2 text-xs font-bold text-center text-black uppercase border-2 border-transparent rounded-md shadow-sm bg-evcoin-gold sm:text-base hover:bg-evcoin-gold hover:bg-opacity-50 sm:w-auto">
          Disconnect Wallet
        </button>
      </div>
    </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BigNumber from "bignumber.js";
import Spinner from './Spinner.vue'
export default {
    name: "AppHeader",
    components: {
      Spinner
    },
    computed: {
      ...mapGetters([
        'isConnected',
        'initLoading',
        'activeNetwork',
        'web3',
        'swap'
      ])
    },
    methods: {
      ...mapActions([
        'init',
        'disconnect'
      ]),
      intToString(num) {
        if (num < 1000) {
            return num;
        }
        let si = [
          {v: 1E3, s: "K"},
          {v: 1E6, s: "M"},
          {v: 1E9, s: "B"},
          {v: 1E12, s: "T"},
          {v: 1E15, s: "P"},
          {v: 1E18, s: "E"}
          ];
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].v) {
                break;
            }
        }
        return `${(num / si[i].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].s}`;
      },
      start_and_end(str) {
        return str.substr(0, 6) + '...' + str.substr(str.length-4, str.length);
      },
      userBalanceFormatted(tokenInfo) {
        return new BigNumber(tokenInfo.userBalance)
          .div(new BigNumber(10).pow(tokenInfo.decimals))
          .toFormat(2);
      },
    }
}
</script>