import BigNumber from "bignumber.js";
import sleep from "../../factories/Sleep";
import ExponentialBackoff from "../../factories/ExponentialBackoff";
import AtomicSwapOracle from "../../factories/AtomicSwapOracle";
import ERC20 from "../../factories/web3/ERC20";
import MTGYAtomicSwapInstance from "../../factories/web3/MTGYAtomicSwapInstance";
import MTGYAtomicSwap from "../../factories/web3/MTGYAtomicSwap";
import Web3Modal from "../../factories/web3/Web3Modal";

export default {
    state: () => ({
      initLoading: false,
      activeNetwork: '',
      web3: {
        instance: null,
        isConnected: false,
        chainId: null,
        address: "",
        userMtgyBalance: "",
        mainCurrencyBalance: "",
      },
      selectedAddressInfo: {
        address: "",
        name: "",
        symbol: "",
        decimals: "",
        userBalance: "",
      },
      asaas: {
        createSwapCost: null,
        swapCost: null,
        instanceGasCost: {},
        instanceServiceCost: {},
        gas: null,
        swaps: [],
      },
      eth: {
        networks: [
          {
            name: "Binance Smart Chain",
            short_name: "bsc",
            chain: "smartchain",
            network: "mainnet",
            chain_id: 56,
            network_id: 56,
            explorer_url: "https://bscscan.com",
            rpc_url: "https://bsc-dataseed.binance.org/",
            blocks_per_day: 28800,
            native_currency: {
              symbol: "BNB",
              name: "BNB",
              decimals: 18,
              contractAddress: "",
              balance: "",
            },
            logo: `img/bsc.png`,
            contracts: {
              mtgy: "0x025c9f1146d4d94F8F369B9d98104300A3c8ca23",
              mtgySpend: "0x8F70517bc8D336dB91f5f3f8aBB4B58e61786B83",
              airdropper: "0xeFD47d675683c2788f8171Fede12A1505D07c2B2",
              atomicSwap: "0x5b88b0CFAF3f97fb1a66B16681F6E502Ec03627e",
              atomicSwap_V1: "0x3d2C8A4a5785fce1bCF86481510d505371c0556d",
              passwordManager: "0xf67f6A36d751677D67069F359Be7623c4ea04524",
              trustedTimestamping: "0x5Cfc47359381526615F7EB91D8460F4Eb73534e1",
              faas: "0xaA0c2852F5391919b8AcE9ac079cf3791E5fE7e7",
              faas_V12: "0xdBD8E0c519B0832a2037D18f32f304C3aDDEA723",
              faas_V13: "0x1e07f7ad3e722F434604e7617d6DAe0a9A48a878",
            },
            buy: {
              link:
                "https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0x025c9f1146d4d94f8f369b9d98104300a3c8ca23",
              img: "img/pancakeswap.png",
              text: "PancakeSwap",
            },
          },
          {
            name: "Ethereum Mainnet",
            short_name: "eth",
            chain: "ETH",
            network: "mainnet",
            chain_id: 1,
            network_id: 1,
            explorer_url: "https://etherscan.io",
            rpc_url: "https://mainnet.infura.io/v3/%API_KEY%",
            blocks_per_day: 6450,
            native_currency: {
              symbol: "ETH",
              name: "Ethereum",
              decimals: 18,
              contractAddress: "",
              balance: "",
            },
            contracts: {
              mtgy: "0x025c9f1146d4d94F8F369B9d98104300A3c8ca23",
              mtgySpend: "0x8A31f723FBfa371308e5f5Dd637246E0F6C573a5",
              airdropper: "0x8f70517bc8d336db91f5f3f8abb4b58e61786b83",
              atomicSwap: "0xa6C81ea7a97432f330F878650A5d5d376795D919",
              atomicSwap_V1: "0x48d6F1Fa74ec4E752b5f7f3cf112aC783251713D",
              passwordManager: "0xc8DD32752abe732Bc586dd42740895B6736619e2",
              trustedTimestamping: "0x266BFfc052a5F02d4797A3DD99C3455Ac9D49eb6",
              faas: "0x306996a2F45F3CA3472cABa245d772D566d4aab7",
              faas_V13: "0x5Cfc47359381526615F7EB91D8460F4Eb73534e1",
              kether: "0xb5fe93ccfec708145d6278b0c71ce60aa75ef925",
              ketherNFT: "0x7bb952AB78b28a62b1525acA54A71E7Aa6177645",
              ketherNFTLoaner: "0x6d02744ef4418CB0D72f54c1eE53140430b9dBEd",
            },
            buy: {
              link:
                "https://app.uniswap.org/#/swap?outputCurrency=0x025c9f1146d4d94f8f369b9d98104300a3c8ca23",
              img: "img/uniswap.png",
              text: "Uniswap",
            },
          },
        ]
      }
    }),
    getters: {
      isConnected(state) {
        return state.web3.isConnected;
      },
    
      initLoading(state) {
        return state.initLoading
      },
    
      activeNetwork(state) {
        return state.eth.networks.find((n) => n.chain_id === state.web3.chainId);
      },
    
      swap(state,getters) {
        if (state.web3.isConnected && !state.initLoading && state.asaas.swaps.length > 0) {
          return state.asaas.swaps.filter(fs => {
            if (getters.activeNetwork.short_name == 'eth' && fs.targetToken != null) {
              return fs.targetNetwork == 'bsc' && fs.targetToken.targetTokenName == 'The moontography project'
            } else if(getters.activeNetwork.short_name == 'bsc' && fs.targetToken != null) {
              return fs.targetNetwork == 'eth' && fs.targetToken.targetTokenName == 'The moontography project'
            }
          })[0]
        } else {
          return null
        }
      },
    
      web3(state) {
        return state.web3
      }
    },
    mutations: {
      SET_INIT_LOADING(state, isLoading) {
        state.initLoading = isLoading;
      },
    
      SET_SELECTED_ADDRESS(state, address) {
        state.selectedAddressInfo.address = address;
      },
    
      SET_SELECTED_ADDRESS_INFO(state, info) {
        const keys = Object.keys(info);
        state.selectedAddressInfo = {
          ...state.selectedAddressInfo,
          ...keys.reduce((o, key) => ({ ...o, [key]: info[key] }), {}),
        };
      },
    
      SET_WEB3_IS_CONNECTED(state, isConnected) {
        state.web3.isConnected = isConnected;
      },
    
      SET_WEB3_CHAIN_ID(state, chainId) {
        state.web3.chainId = chainId;
      },
    
      SET_WEB3_INSTANCE(state, web3) {
        state.web3.instance = web3;
      },
    
      SET_WEB3_PROVIDER(state, provider) {
        state.web3.provider = provider;
      },
    
      SET_WEB3_USER_ADDRESS(state, addy) {
        state.web3.address = addy;
      },
    
      SET_WEB3_USER_MTGY_BALANCE(state, balance) {
        state.web3.userMtgyBalance = balance;
      },
    
      SET_WEB3_MAIN_BALANCE(state, balance) {
        state.web3.mainCurrencyBalance = balance;
      },
    
      SET_ASAAS_INSTANCE_GAS_COST(state, { contractAddress, instanceGasCost }) {
        state.asaas.instanceGasCost = {
          ...state.asaas.instanceGasCost,
          [contractAddress]: instanceGasCost,
        };
      },
    
      SET_ASAAS_INSTANCE_SERVICE_COST(state, { contractAddress, serviceCost }) {
        state.asaas.instanceServiceCost = {
          ...state.asaas.instanceServiceCost,
          [contractAddress]: serviceCost,
        };
      },
    
      SET_ASAAS_SWAPS(state, contracts) {
        state.asaas.swaps = contracts;
      },
    },
    actions: {
      async init({commit, dispatch, getters, state}, reset = false) {
        try {
          if (state.web3 && state.web3.isConnected && !reset) return;
          commit("SET_INIT_LOADING", true)
          const { provider, web3 } = await Web3Modal.connect();
          commit("SET_WEB3_PROVIDER", provider);
          commit("SET_WEB3_INSTANCE", web3);
          commit("SET_WEB3_IS_CONNECTED", true);
          const resetConnection = async () => {
            dispatch("disconnect");
            await dispatch("init", true);
            localStorage.evcoinLoggedIn = true
            localStorage.WEB3_CONNECT_CACHED_PROVIDER = '"injected"'
          };
          Web3Modal.bindProviderEvents({
            accountsChanged: resetConnection,
            chainChanged: resetConnection,
            disconnect: () => dispatch("disconnect"),
          });
    
          commit("SET_WEB3_CHAIN_ID", await web3.eth.getChainId());
          const [accountAddy] = await web3.eth.getAccounts();
          commit("SET_WEB3_USER_ADDRESS", accountAddy);
          await dispatch('getAllSwapContracts');
        } catch(err) {
          console.log(err)
        } finally {
          commit("SET_INIT_LOADING", false)
          localStorage.evcoinLoggedIn = true
        }
      },
      disconnect({ commit }) {
        commit("SET_WEB3_PROVIDER", null);
        commit("SET_WEB3_INSTANCE", null);
        commit("SET_WEB3_IS_CONNECTED", false);
        commit("SET_WEB3_CHAIN_ID", null);
        commit("SET_WEB3_USER_ADDRESS", "");
    
        // Clear cached provider to be able to switch between providers when disconnecting wallet
        Web3Modal.clearCachedProvider();
        localStorage.removeItem("evcoinLoggedIn")
      },
      async asaasInstanceGasCost({ commit, state }, contractAddress) {
        const web3 = state.web3.instance;
        // const userAddy = state.web3.address;
        // const activeNetwork = getters.activeNetwork;
        const contract = MTGYAtomicSwapInstance(web3, contractAddress);
        const [instanceGasCost, serviceCost] = await Promise.all([
          contract.methods.minimumGasForOperation().call(),
          contract.methods.mtgyServiceCost().call(),
        ]);
        commit("SET_ASAAS_INSTANCE_GAS_COST", { contractAddress, instanceGasCost });
        commit("SET_ASAAS_INSTANCE_SERVICE_COST", { contractAddress, serviceCost });
      },
      async getAllSwapContracts({ commit, dispatch, getters, state }) {
        const web3 = state.web3.instance;
        const userAddy = state.web3.address;
        const activeNetwork = getters.activeNetwork;
    
        if (!activeNetwork) {
          await sleep(500);
          return await dispatch("getAllSwapContracts");
        }
        const asaasAddy = activeNetwork.contracts.atomicSwap;
        const asaasAddy_V1 = activeNetwork.contracts.atomicSwap_V1;
        const contract = MTGYAtomicSwap(web3, asaasAddy);
        const contract_V1 = asaasAddy_V1 && MTGYAtomicSwap(web3, asaasAddy_V1);
        const [allSwaps, allSwaps_V1] = await Promise.all([
          contract.methods.getAllSwapContracts().call(),
          (async () => {
            if (contract_V1) {
              return await contract_V1.methods.getAllSwapContracts().call();
            }
            return [];
          })(),
        ]);
        const mappedSwaps = await Promise.all(
          allSwaps.concat(allSwaps_V1).map(async (swap) => {
            try {
              const sourceSwapInst = MTGYAtomicSwapInstance(
                web3,
                swap.sourceContract
              );
    
              const isSwapActive = await sourceSwapInst.methods.isActive().call();
              if (!(isSwapActive && swap.isActive)) return null;
    
              const [
                swapTokenAddy,
                targetToken,
                unclaimedSentFromSource,
                { swap: unclaimedSentFromTarget },
              ] = await Promise.all([
                sourceSwapInst.methods.getSwapTokenAddress().call(),
                (async () => {
                  try {
                    return await AtomicSwapOracle.getSwap({
                      userAddress: userAddy,
                      sourceNetwork: activeNetwork.short_name,
                      sourceContract: swap.sourceContract,
                    });
                  } catch (err) {
                    console.error(`Error getting swap info`, err);
                    return null;
                  }
                })(),
                (async () => {
                  const lastUserSwap = await sourceSwapInst.methods
                    .lastUserSwap(userAddy)
                    .call();
                  if (lastUserSwap) {
                    return await sourceSwapInst.methods
                      .swaps(lastUserSwap.id)
                      .call();
                  }
                })(),
                (async () => {
                  try {
                    return await AtomicSwapOracle.getLastUserSwap(
                      swap.targetNetwork,
                      userAddy,
                      swap.targetContract
                    );
                  } catch (err) {
                    console.error(`Error getting target unclaimed info`, err);
                    return null;
                  }
                })(),
              ]);
              const token = await dispatch("getErc20TokenInfo", swapTokenAddy);
              const tokenCont = ERC20(web3, token.address);
              return {
                unclaimedSentFromSource,
                unclaimedSentFromTarget,
                targetToken,
                token: {
                  ...token,
                  contractBalance: await tokenCont.methods
                    .balanceOf(swap.sourceContract)
                    .call(),
                },
                ...swap,
              };
            } catch (err) {
              console.error(`Error get swap`, err);
              return null;
            }
          })
        );
        commit(
          "SET_ASAAS_SWAPS",
          mappedSwaps.filter((s) => !!s)
        );
      },
      async asaasGetLatestUserSwap({ state }, sourceContract) {
        const web3 = state.web3.instance;
        const userAddy = state.web3.address;
        const contract = MTGYAtomicSwapInstance(web3, sourceContract);
        return await contract.methods.lastUserSwap(userAddy).call();
      },
      async getErc20TokenInfo({ state }, tokenAddy) {
        const userAddy = state.web3.address;
        const contract = ERC20(state.web3.instance, tokenAddy);
        const [name, symbol, decimals, userBalance] = await Promise.all([
          contract.methods.name().call(),
          contract.methods.symbol().call(),
          contract.methods.decimals().call(),
          contract.methods.balanceOf(userAddy).call(),
        ]);
        return {
          address: tokenAddy,
          name,
          symbol,
          decimals,
          userBalance,
        };
      },
      async sendTokensToSwap(
        { dispatch, getters, state },
        { amount, sourceContract, tokenContract }
      ) {
        const web3 = state.web3.instance;
        const userAddy = state.web3.address;
        const mtgyAddy = getters.activeNetwork.contracts.mtgy;
        const mtgyCont = ERC20(web3, mtgyAddy);
        const contract = MTGYAtomicSwapInstance(web3, sourceContract);
        const [instanceServiceCost, userMtgyBal] = await Promise.all([
          contract.methods.mtgyServiceCost().call(),
          mtgyCont.methods.balanceOf(userAddy).call(),
        ]);
    
        // validate amount is valid
        const servCostHumanReadable = new BigNumber(instanceServiceCost)
          .div(new BigNumber(10).pow(18))
          .toFormat();
        if (new BigNumber(instanceServiceCost).gt(userMtgyBal)) {
          throw new Error(
            `You need to make sure you have at least ${servCostHumanReadable} MTGY to spend to use this service.`
          );
        } else if (tokenContract.toLowerCase() === mtgyAddy.toLowerCase()) {
          // need to make sure the send amount is less than the
          // user's balance and mtgyServiceCost
          if (
            new BigNumber(userMtgyBal).lt(
              new BigNumber(instanceServiceCost).plus(amount)
            )
          ) {
            throw new Error(
              `You need to make sure the amount you swap leaves you with at least ${servCostHumanReadable} MTGY to cover the service cost.`
            );
          }
        }
    
        if (new BigNumber(instanceServiceCost).gt(0)) {
          await dispatch("genericErc20Approval", {
            spendAmount: instanceServiceCost,
            tokenAddress: mtgyAddy,
            delegateAddress: sourceContract,
          });
        }
        await dispatch("genericErc20Approval", {
          spendAmount: amount,
          tokenAddress: tokenContract,
          delegateAddress: sourceContract,
        });
        return await contract.methods.receiveTokensFromSource(amount).send({
          from: userAddy,
          value: state.asaas.instanceGasCost[sourceContract],
        });
      },
      async genericErc20Approval(
        { state },
        { spendAmount, tokenAddress, delegateAddress, unlimited }
      ) {
        if (new BigNumber(spendAmount || 0).lte(0)) return;
    
        unlimited = unlimited === false ? false : true;
        const userAddy = state.web3.address;
        const contract = ERC20(state.web3.instance, tokenAddress);
        const [userBalance, currentAllowance] = await ExponentialBackoff(
          async () => {
            return await Promise.all([
              contract.methods.balanceOf(userAddy).call(),
              contract.methods.allowance(userAddy, delegateAddress).call(),
            ]);
          }
        );
        if (new BigNumber(currentAllowance).lte(spendAmount || 0)) {
          await contract.methods
            .approve(
              delegateAddress,
              unlimited ? new BigNumber(2).pow(256).minus(1).toFixed() : userBalance
            )
            .send({ from: userAddy });
        }
      },
      async asaasFundAndClaimTokens(
        { getters, state },
        { instContract, id, timestamp, amount }
      ) {
        const web3 = state.web3.instance;
        const activeNetwork = getters.activeNetwork;
        const userAddy = state.web3.address;
        const contract = MTGYAtomicSwapInstance(web3, instContract);
        const [valueToSend, currentSwap] = await Promise.all([
          contract.methods.minimumGasForOperation().call(),
          contract.methods.swaps(id).call(),
        ]);
        if (!currentSwap.isSendGasFunded) {
          await contract.methods
            .fundSendToDestinationGas(id, timestamp, amount)
            .send({ from: userAddy, value: valueToSend });
        }
        await AtomicSwapOracle.sendTokens({
          targetNetwork: activeNetwork.short_name,
          targetContract: instContract,
          targetSwapId: id,
        });
    
        // poll for completion status to handle txns taking longer than
        // the 30 second HTTP limit (some chains can be deathly slow/congested)
        let isComplete = false;
        let tries = 0;
        let waitIntervalSec = 5;
        let numTotalTries = (2 * 60) / 5; // 2 min of tries
        while (!isComplete && tries < numTotalTries) {
          const { source, target } = await AtomicSwapOracle.sendTokens({
            checkOnly: true,
            targetNetwork: activeNetwork.short_name,
            targetContract: instContract,
            targetSwapId: id,
          });
          // If 'source' is complete it means the user received their tokens
          // which is all they care about anyway, so only check source for now
          // isComplete = source && target;
          isComplete = source;
          if (!isComplete) {
            await sleep(waitIntervalSec * 1e3);
          }
          tries++;
        }
        if (tries >= numTotalTries) {
          throw new Error(
            `Please check and confirm your tokens have landed in your wallet. If not please try clicking the claim button one more time. If you see this message again, please contract support to claim your tokens.`
          );
        }
      },
      async refundTokens(
        { getters, state },
        { instContract, id, timestamp, amount }
      ) {
        const web3 = state.web3.instance;
        const activeNetwork = getters.activeNetwork;
        const userAddy = state.web3.address;
        const contract = MTGYAtomicSwapInstance(web3, instContract);
        const [valueToSend, currentSwap] = await Promise.all([
          contract.methods.minimumGasForOperation().call(),
          contract.methods.swaps(id).call(),
        ]);
        if (!currentSwap.isSendGasFunded) {
          await contract.methods
            .fundSendToDestinationGas(id, timestamp, amount)
            .send({ from: userAddy, value: valueToSend });
        }
        await AtomicSwapOracle.refundTokens({
          targetNetwork: activeNetwork.short_name,
          targetContract: instContract,
          targetSwapId: id,
        });
    
        // poll for completion status to handle txns taking longer than
        // the 30 second HTTP limit (some chains can be deathly slow/congested)
        let isComplete = false;
        let tries = 0;
        let waitIntervalSec = 5;
        let numTotalTries = (2 * 60) / 5; // 2 min of tries
        while (!isComplete && tries < numTotalTries) {
          const { source: isRefundedYet } = await AtomicSwapOracle.refundTokens({
            checkOnly: true,
            targetNetwork: activeNetwork.short_name,
            targetContract: instContract,
            targetSwapId: id,
          });
          // If 'source' is complete it means the user received got refunded
          isComplete = isRefundedYet;
          if (!isComplete) {
            await sleep(waitIntervalSec * 1e3);
          }
          tries++;
        }
        if (tries >= numTotalTries) {
          throw new Error(
            `Please check and confirm your tokens have landed in your wallet. If not please try clicking the refund button one more time. If you see this message again, please contract support to refund your tokens.`
          );
        }
      }
    }
}