<template>
  <div>
    <div
      class="flex items-center justify-center rounded-lg bg-babydefido-network"
      v-if="network == 'ERC20'"
    >
      <ethereum />
      <svg
        class="w-4 h-4 mx-10"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0V20L17 10L0 0Z" fill="white" />
      </svg>
      <binance />
    </div>
    <div
      v-else
      class="flex items-center justify-center rounded-lg bg-babydefido-network"
    >
      <binance />
      <svg
        class="w-4 h-4 mx-10"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0V20L17 10L0 0Z" fill="white" />
      </svg>
      <ethereum />
    </div>
  </div>
</template>

<script>
import Binance from "./Binance.vue";
import Ethereum from "./Ethereum.vue";
export default {
  name: "Network",
  components: {
    Binance,
    Ethereum,
  },
  props: {
    network: {
      type: String,
      default: "ERC20",
    },
  },
};
</script>
