<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      class="w-8 h-8 ml-2 animate-bounce"
    >
      <circle
        cx="84"
        cy="50"
        r="10"
        fill="rgb(3, 21, 58)"
        style="animation-play-state: paused"
      ></circle>
      <circle
        cx="16"
        cy="50"
        r="0"
        fill="#03153a"
        style="animation-play-state: paused"
      ></circle>
      <circle
        cx="16"
        cy="50"
        r="0"
        fill="#03153a"
        style="animation-play-state: paused"
      ></circle>
      <circle
        cx="16"
        cy="50"
        r="10"
        fill="#03153a"
        style="animation-play-state: paused"
      ></circle>
      <circle
        cx="50"
        cy="50"
        r="10"
        fill="#03153a"
        style="animation-play-state: paused"
      ></circle>
      <!-- generated by https://loading.io/ -->
    </svg>
  </div>
</template>
